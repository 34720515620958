import _usePaginatedProductFeeds_Products_company from "./__generated__/usePaginatedProductFeeds_Products_company.graphql";
import { graphql, createPaginationContext } from '@attentive/data';
import CompanyFragment from './__generated__/usePaginatedProductFeeds_Products_company.graphql';
export const RESULTS_PER_PAGE = 8;
_usePaginatedProductFeeds_Products_company;

const getPageInfoFragment = data => {
  var _data$productSegments;

  return (data === null || data === void 0 ? void 0 : (_data$productSegments = data.productSegments) === null || _data$productSegments === void 0 ? void 0 : _data$productSegments.pageInfo) || null;
};

const [useProductFeedsPaginationContext, ProductFeedsPaginationContext] = createPaginationContext({
  query: CompanyFragment,
  options: {
    // refresh the data so that newly added product feeds are displayed
    fetchPolicy: 'network-only'
  },
  getPageInfoFragment
});
export { useProductFeedsPaginationContext, ProductFeedsPaginationContext };