/**
 * @generated SignedSource<<d26c1784eb96ffef354f1b43e768a6ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductFeedsList_Products_Query$variables = {
  after?: string | null;
  before?: string | null;
  companyId: string;
  first?: number | null;
  last?: number | null;
};
export type ProductFeedsList_Products_Query$data = {
  readonly company: {
    readonly id?: string;
    readonly productSegmentsTemp?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        };
      }>;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"usePaginatedProductFeeds_Products_company">;
  } | null;
};
export type ProductFeedsList_Products_Query = {
  response: ProductFeedsList_Products_Query$data;
  variables: ProductFeedsList_Products_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": "productSegmentsTemp",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    }
  ],
  "concreteType": "ProductSegmentsConnection",
  "kind": "LinkedField",
  "name": "productSegments",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductSegmentEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductSegment",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "productSegments(first:1)"
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductFeedsList_Products_Query",
    "selections": [
      {
        "alias": "company",
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "args": (v8/*: any*/),
                "kind": "FragmentSpread",
                "name": "usePaginatedProductFeeds_Products_company"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProductFeedsList_Products_Query",
    "selections": [
      {
        "alias": "company",
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "ProductSegmentsConnection",
                "kind": "LinkedField",
                "name": "productSegments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductSegmentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductSegment",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "productCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "updated",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Url",
                            "kind": "LinkedField",
                            "name": "imageUrl",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "value",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a047fa72073e0ee335d87829f5296dd6",
    "id": null,
    "metadata": {},
    "name": "ProductFeedsList_Products_Query",
    "operationKind": "query",
    "text": "query ProductFeedsList_Products_Query(\n  $companyId: ID!\n  $first: Int\n  $after: String\n  $before: String\n  $last: Int\n) {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      id\n      productSegmentsTemp: productSegments(first: 1) {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n      ...usePaginatedProductFeeds_Products_company_pbnwq\n    }\n    id\n  }\n}\n\nfragment ProductFeedsTableBody_Products_productSegmentsConnection on ProductSegmentsConnection {\n  edges {\n    node {\n      id\n      ...ProductFeedsTableRow_Products_productSegment\n    }\n  }\n}\n\nfragment ProductFeedsTableRow_Products_productSegment on ProductSegment {\n  id\n  name\n  productCount\n  updated\n  imageUrl {\n    value\n  }\n}\n\nfragment usePaginatedDataFragment_pageInfo on PageInfo {\n  endCursor\n  hasNextPage\n}\n\nfragment usePaginatedProductFeeds_Products_company_pbnwq on Company {\n  productSegments(first: $first, after: $after, before: $before, last: $last) {\n    pageInfo {\n      ...usePaginatedDataFragment_pageInfo\n    }\n    ...ProductFeedsTableBody_Products_productSegmentsConnection\n    edges {\n      cursor\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "9c097c552277702983e55f9bb84903a3";

export default node;
