/**
 * @generated SignedSource<<a9769c0f810f761da5ea23e54e61305f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePaginatedProductFeeds_Products_company$data = {
  readonly id: string;
  readonly productSegments: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
    }>;
    readonly pageInfo: {
      readonly " $fragmentSpreads": FragmentRefs<"usePaginatedDataFragment_pageInfo">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ProductFeedsTableBody_Products_productSegmentsConnection">;
  } | null;
  readonly " $fragmentType": "usePaginatedProductFeeds_Products_company";
};
export type usePaginatedProductFeeds_Products_company$key = {
  readonly " $data"?: usePaginatedProductFeeds_Products_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePaginatedProductFeeds_Products_company">;
};

import usePaginatedProductFeeds_Products_company_refetchable_graphql from './usePaginatedProductFeeds_Products_company_refetchable.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": usePaginatedProductFeeds_Products_company_refetchable_graphql,
      "identifierField": "id"
    }
  },
  "name": "usePaginatedProductFeeds_Products_company",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "before",
          "variableName": "before"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "last",
          "variableName": "last"
        }
      ],
      "concreteType": "ProductSegmentsConnection",
      "kind": "LinkedField",
      "name": "productSegments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "usePaginatedDataFragment_pageInfo"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProductFeedsTableBody_Products_productSegmentsConnection"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductSegmentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "7c4725865c55847f2a103d896508fe7a";

export default node;
